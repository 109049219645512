.media-rust{
    margin-bottom: 120px;

    .container{
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 100%;

        .navbar-backdrop{
            background: rgba(0, 0, 0, 0.6);
        }

        & > .box-gradient{
            opacity: 0;
            
            &:before{
                --color-a:#8359e5;
                --color-b:#8d9bed;
                --color-c:#5dd8f7;
            }
        }

        &.active{

            .box-gradient{
                animation: fadeIn 0.5s 0.5s forwards;
            }
            .media-rust-title{
                animation: fadeIn 1s 0.75s forwards;
            }
            .media-rust-image{
                animation: textReveal 0.8s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
                &[id-animation="1"]{
                    animation-delay: 0.9s;
                }
                &[id-animation="2"]{
                    animation-delay: 1.05s;
                }
                &[id-animation="3"]{
                    animation-delay: 1.2s;
                }
                &[id-animation="4"]{
                    animation-delay: 1.35s;
                }
                &[id-animation="5"]{
                    animation-delay: 1.5s;
                }
                &[id-animation="6"]{
                    animation-delay: 1.65s;
                }
                &[id-animation="7"]{
                    animation-delay: 1.8s;
                }
                &[id-animation="8"]{
                    animation-delay: 1.95s;
                }
            }
            .media-slider-indicator{
                animation: fadeIn 1s 1.5s forwards;
            }
        }
    }
}

.media-rust-title{
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 64px;
    line-height: 90%;
    opacity: 0;
}

.media-rust-slider{
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: grab;
}

.media-inner-slider-container{
    display: flex;
    flex-grow: 1;
}

.media-rust-inner-slider{
    display: flex;
    will-change: transform;
    flex-grow: 1;
}

.media-rust-image{
    width: 336px;
    height: 180px;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 16px;
    opacity: 0;
    position: relative;

    &.internal-rust{
        width: 336px;
        height: 180px;
    }

    &:before{
        z-index: 3;
        content: "";
        display: flex;
        width: calc(100% - 16px);
        height: 100%;
        background: linear-gradient(to bottom, rgb(224, 170, 89), rgba(255,255,255,0));
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 0.4s ease;
        border-radius: 8px;
        pointer-events: none;
    }

    &.external-rust:before{
        background: linear-gradient(to bottom, #43deec, rgba(255,255,255,0));
    }

    &:hover:before{
        opacity: 0.2;
    }

    &:first-child{
        border-radius: 8px 0px 0px 8px;
    }

    &:last-child{
        border-radius: 0px 8px 8px 0px;
        padding-right: 0px;
        width: 320px;

        &:before{
            width: 100%;
        }
    }


    img{
        display: flex;
        width: 100%;
        align-self: flex-start;
        object-fit: cover;
        border-radius: 8px;
    }
}

.media-slider-indicator-container{
    width: 100%;
    margin-top: 64px;
    display: flex;
    justify-content: center;
}

.media-slider-indicator{
    opacity: 0;
    width: 100%;
    max-width: 600px;
    height: 6px;
    border-radius: 9999px;
    overflow: hidden;
    background: rgba(160, 160, 160, 0.3);
}

.media-slider-indicator-inner{
    height: 6px;
    border-radius: 9999px;
    background: #dca865;
}

.image-gallery-container{
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
}

.gallery-button{
    display: flex;
    width: 48px;
    position: absolute;
    top: 50%;
    translate:0 -50%;
    cursor: pointer;
    user-select: none;
    z-index: 1;

    img{
        width: 100%;
    }

    
    &:hover{
        filter: contrast(0.3);
    }
    
    &.gallery-button-left{
        left: 24px;
        transition: 0.3s ease;
        rotate: 180deg;

        &:active{
            transform: translateX(10px);
        }
    }
    &.gallery-button-right{
        right: 24px;
        transition: 0.3s ease;

        &:active{
            transform: translateX(10px);
        }
    }
}

.galley-image-box{
    width: calc(100% - 48px);
    max-width: 640px;
    aspect-ratio: 800 / 450;
    overflow: hidden;

    display: flex;
    user-select: none;
    justify-content: flex-start;
    align-items: flex-start;

    &.internal-rust{ 
        aspect-ratio: 789 / 754;
    }

    img{
        object-fit: contain;
        width: 100%;
        user-select: none;    
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 12px;   
    }
}

.gallery-image-counter{
    display: flex;
    font-size: 24px;
    font-weight: 500;
    position: absolute;
    top: 36px;
    left: 24px;
    width: 48px;
    align-items: center;
    justify-content: flex-end;
    
    span{
        line-height: 90%;
    }
}

.gallery-modal-close{
    cursor: pointer;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 36px;
    right: 24px;
    display: flex;
}

.buy .media-slider-indicator-container{
    display: none;
}

.media-slider-indicator-container.main-page{
    display: flex;
}

@media screen and (max-width: 1280px) {
    .buy .media-slider-indicator-container{
        display: flex;
    }
}
@media screen and (max-width: 860px) {
    .gallery-button{
        width: 36px;
    }
}
@media screen and (max-width: 590px) {
    .media-rust-title{
        font-size: 32px;
        margin-bottom: 48px;
    }
    .media-slider-indicator-container{
        margin-top: 48px;
    }
    .media-rust-image{
        width: 320px;
        height: 180px;
    
        &:last-child{
            width: 304px;
        }
    }

    .gallery-button{
        display: flex;
        width: 32px;
        position: absolute;
        top: calc(100% - 32px - 32px);
        translate:0 0;
        cursor: pointer;
        user-select: none;
        z-index: 1;
    }
}