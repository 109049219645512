.hero-rust{
    padding: 160px 0;

    .container{
        display: flex;
        gap: 96px;

        .hero-bg-image-box{
            animation: fadeIn 1s 0.5s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
        }
    }
}

.hero-rust-left-title{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 520px;
    margin-bottom: 48px;
    opacity: 0;
    animation: fadeIn 1s 0.75s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
}

.hero-rust-left-top{
    display: flex;
    align-items: center;
    gap: 16px;

    img{
        width: 64px;
        border-radius: 8px;
    }
}

.hero-rust-left-title-text{
    display: flex;
    flex-direction: column;
    gap: 10px;
    span{
        font-size: 14px;
        line-height: 90%;
    }
    h2{
        font-size: 32px;
        line-height: 90%;
    }
}

.hero-rust-left-title-text-game{
    display: flex;
    gap: 4px;
    span:first-child{
        color: #8a8f98;
    }
    span:last-child{
        color: #659fdc;
        font-weight: 600;
    }
}

.hero-rust-left-subtitle{
    color: #cbd5e1;
}

.hero-rust-requirements{
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fadeIn 1s 0.9s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
}

.requirements-title{
    position: relative;
    font-size: 24px;
    padding-bottom: 8px;
    text-transform: uppercase;
    font-weight: 700;

    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, #fff 50%, transparent);
        left: 0;
        bottom: 0;
        opacity: 0.2;
    }
}

.requirements-box{
    padding: 16px 0;
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
}

.requirement{
    display: flex;
    flex-direction: column;
}

.requirement-title{
    font-weight: 500;
}

.requirement-text{
    color: #8a8f98;
    font-size: 14px;

    &.undetected{
        color: #659fdc;
    }
    &.detected{
        color: #fd5454;
    }
    &.down{
        color: #fd5454;
    }
    &.maintenance{
        color: #f1be31;
    }
}

.hero-rust-right{
    width: 100%;
}

.hero-rust-video-box{
    display: flex;
    opacity: 0;
    animation: cheatReveal 1s 1.6s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 12px;
}

.hero-rust-right-title{
        position: relative;
        font-size: 24px;
        padding-bottom: 8px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 24px;
        opacity: 0;
        animation: fadeIn 1s 1.3s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
    
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, #fff 50%, transparent);
            left: 0;
            bottom: 0;
            opacity: 0.2;
        }
}

.hero-rust-prices-btn{
    display: flex;
    width: 100%;
    padding: 12px 0;
    justify-content: center;
    border-radius: 12px;
    margin-top: 24px;
    font-weight: 500;
    background: rgba(80, 149, 196, 0.5);
    transition: 0.4s ease;
    opacity: 0;
    animation: fadeIn 1s 1.05s cubic-bezier(0.27,0.94,0.48,1.01) forwards;

    &:hover{
        background: rgba(160, 160, 160, 0.356);
    }
}


@media screen and (max-width: 1440px){
    .hero-rust{
        padding: 120px 0;
    }
}
@media screen and (max-width: 1280px){
    .hero-rust{
        padding: 96px 0;
    }
}

@media screen and (max-width: 1100px){
    .hero-rust{
        padding: 80px 0;
        .container{
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 590px){
    .hero-rust{
        padding-top: 64px;
    }

    .hero-rust-left-title{
        width: 100%;
    }

    .hero-rust-left-top{
        display: flex;
        align-items: center;
        gap: 16px;
    
        img{
            width: 48px;
            border-radius: 8px;
        }
    }

    .hero-rust-left-title-text{
        display: flex;
        flex-direction: column;
        gap: 10px;
        span{
            font-size: 14px;
            line-height: 90%;
        }
        h2{
            font-size: 24px;
            line-height: 90%;
        }
    }

    .hero-rust-left-subtitle{
        font-size: 14px;
    }

    .requirements-box{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 361px){

    .hero-rust-left-title-text{
        h2{
            font-size: 19px;
            line-height: 90%;
        }
    }
}