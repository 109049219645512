.features-rust{
    .container{
        display: flex;
        flex-direction: column;
        position: relative;
        transition: 0.5s cubic-bezier(0.37,0.5,0.44,0.94);
        margin-bottom: 64px;

        &[style="max-height: 320px;"]{
            .feature-content-container{
                mask-image: linear-gradient(to top, transparent, #fff 200px);
                -webkit-mask-image: linear-gradient(to top, transparent, 200px);
            }
        }

        &.active{
            .features-title {
                animation: fadeIn 1s 0.75s forwards;
            }
            .feature-content-box{
                animation: textReveal 0.8s cubic-bezier(0.27,0.94,0.48,1.01) forwards;
                &[id-animation="1"]{
                    animation-delay: 0.9s;
                }
                &[id-animation="2"]{
                    animation-delay: 1.05s;
                }
                &[id-animation="3"]{
                    animation-delay: 1.2s;
                }
                &[id-animation="4"]{
                    animation-delay: 1.35s;
                }
                &[id-animation="5"]{
                    animation-delay: 1.5s;
                }
                &[id-animation="6"]{
                    animation-delay: 1.65s;
                }
                &[id-animation="7"]{
                    animation-delay: 1.8s;
                }
                &[id-animation="8"]{
                    animation-delay: 1.95s;
                }
                &[id-animation="9"]{
                    animation-delay: 2.1s;
                }
                &[id-animation="10"]{
                    animation-delay: 2.25s;
                }
                &[id-animation="11"]{
                    animation-delay: 2.4s;
                }
                &[id-animation="12"]{
                    animation-delay: 2.55s;
                }
            }
            .show-more-less{
                & > span{
                    animation: fadeIn 1s 1.35s forwards;
                }
            }
        }
    }
}


.features-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 32px;
    line-height: 90%;
    opacity: 0;
}

.feature-content-container{
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 32px;
    mask-image: linear-gradient(to top, transparent, #fff 0px);
    -webkit-mask-image: linear-gradient(to top, transparent, #fff 0px);
}

.show-more-less{
    position: absolute;
    left: 50%;
    bottom: -64px;
    translate: -50% 0;

    & > span{
        display: block;
        cursor: pointer;
        border-radius: 12px;
        padding: 12px 48px;
        background: rgba(80, 149, 196, 0.5);
        transition: 0.4s ease;
        opacity: 0;

        span{
            display: flex;
            width: 88px;
            justify-content: center;
        }

        &:active{
            scale: 0.9;
        }

        &:hover{
            background: rgba(160, 160, 160, 0.5);
        }
    }
}

.feature-content-features{
    display: flex;
    flex-direction: column;
}

.feature-content-box{
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: 0;
}

.feature-content-title{
    position: relative;
    font-size: 24px;
    padding-bottom: 8px;
    text-transform: uppercase;
    font-weight: 700;

    &::before{
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, #fff 50%, transparent);
        left: 0;
        bottom: 0;
        opacity: 0.2;
    }
}

.feature-content-features{
    color:#cbd5e1;
    font-size: 14px;
}

@media screen and (max-width: 860px){
    .feature-content-container{
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (max-width: 590px){
    .features-title{
        font-size: 32px;
    }

    .feature-content-container{
        grid-template-columns: repeat(1,1fr);
    }
}