@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.faq{
    padding: 64px 0;
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-options{
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.1);

            .active-indicator{
                background: rgb(73, 119, 169);
            }
        }

        .modal-content{
            max-width: 320px;
            width: 100%;
            min-width: 256px;
        }

        .box-gradient{
            opacity: 0;
            
            &:before{
                --color-a:#70b856;
                --color-b:#8deded;
                --color-c:#745df7;
            }
        }

        &.active{
            .box-gradient{
                animation: fadeIn 0.5s 0.5s forwards;
            }

            .s{
                animation: fadeIn 0.4s 0.75s forwards;
            }

            .faq_title{
                animation: fadeIn 0.4s 0.25s forwards;
            }

            .faq_section_select{
                animation: fadeIn 0.4s 0.4s forwards;
            }

            .inctructions_header{
                animation: fadeIn 0.4s 0.55s forwards;

                &[data-id="0"]{
                    animation-delay: 0.55s;
                }
                &[data-id="1"]{
                    animation-delay: 0.85s;
                }
            }

            .faq_accordion_wrapper{
                animation: fadeIn 0.4s 0.7s forwards;
                // slideFromBottom 0.25s 1s forwards;

                &[data-id="0"]{
                    animation-delay: 0.7s;
                }
                &[data-id="1"]{
                    animation-delay: 1s;
                }
            }
        }
    }
}

@keyframes slideFromBottom {
    0%{
        transform: translateY(24px);
    } 100%{
        transform: translateY(0px);
    }
}

.faq_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
    opacity: 0;
}

.faq_title_main{
    font-weight: 500;
    margin-bottom: 8px;
}

.faq_title_sub{
    display: flex;
    color: rgba(255, 255, 255, 0.75);
    line-height: 1.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a{
        color: rgba(255, 255, 255, 0.75);
        text-decoration: underline;
    }
}

.faq_section_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 640px;
}

.faq_section_select{
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
}

.inctructions_header{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 8px;
    opacity: 0;

    p{
        display: flex;
        img{
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-top: 4px;
            margin-right: 8px;
        }
        color: #8deded;

        &.red{
            color: #edc08d;
        }
    }
}

.inctructions_title{
    font-size: 20px;
    font-weight: 600;
}

.faq_accordion_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    // gap: 16px;
    border-radius: 16px;
    // padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 32px;

    opacity: 0;
    // transform: translateY(24px);
}

.faq_accordion{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding: 16px;

    transition: 0.3s background ease;

    &:hover{
        background: rgba(255, 255, 255, 0.075);
    }

    &.active{
        background: rgba(255, 255, 255, 0.05);
    }

    &:first-child{
        border-radius: 16px 16px 0 0;
    }
    &:last-child{
        border-radius: 0 0 16px 16px;
    }
}

.faq_separator{
    width: 100%;
    // width: calc(100% + 32px);
    // transform: translateX(-16px);
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
}

.accordion_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.accordion_icon{
    display: flex;
    max-width: 32px;
    max-height: 32px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba(182, 250, 255, 0.3);
    color: #b8faff99;
    margin-right: 16px;
    opacity: 0.75;

    img{
        width: 100%;
        height: 100%;
    }
}

.accordion_top{
    display: flex;
    justify-content: space-between;
    width: 100%;

    div{
        display: flex;
        max-height: 32px;
        align-items: center;
        margin-left: 8px;
    }

    img{
        transform-origin: center;
        max-width: 24px;
        max-height: 24px;
        // rotate: -180deg;
        transition: 0.3s rotate ease;

        // &.active{
        //     rotate: 360deg;
        // }

        pointer-events: none;
        user-select: none;
    }
}

.accordion_header{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    min-height: 32px;
    padding-top: 2.5px;
}

.accordion_text{
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s grid-template-rows ease;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    cursor: text;

    ul{
        display: flex;
        flex-direction: column;
        margin-top: 6px;
        gap: 4px;

        li{
            list-style-type: disc;
            margin-left: 32px;
        }
    }

    p{
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px;
    }

    code{
        border-radius: 4px;
        padding: 2px 4px;
        border: 1px solid black;
        background: #111111;
    }

    & > div{
        overflow: hidden;
        opacity: 0;
        transition: 0.3s 0.1s opacity ease;

        & > span{
            display: block;
            padding-top: 16px;
        }
    }

    &.active{
        grid-template-rows: 1fr;

        div{
            opacity: 1;
        }
    }
}

.faq_questions_link{
    text-decoration: underline;
}


@media screen and (max-width: 550px) {
    .faq{
        padding: 48px 0;
    }
}

@media screen and (max-width: 440px) {
    .faq{
        padding: 32px 0;
    }

    .inctructions_title{
        font-size: 18px;
    }

    .inctructions_header{
        p{
            font-size: 14px;
        }
    }

    .accordion_header{
        font-size: 16px;
    }

    .faq_accordion{
        display: flex;
        flex-direction: column;
    }

    .accordion_icon{
        margin-right: 0;
        margin-bottom: 8px;
    }
}

.code_section{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 8px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.75);
    border-left: 4px solid #5686AE;
    // overflow: hidden;
    font-family: "Space Mono";
}

.code_top_section{
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 2px 8px;
    border-radius: 0 6px 0 0;
    font-size: 14px;
    align-items: center;
    color: #3A79AC;
    background: rgba(58, 114, 159, 0.5);

    & > img{
        display: block;
        width: 14px;
    }
}

.code_section_content{
    padding: 4px 8px;
    word-break: break-all;

    .code_blue{
        color: #3A79AC;
    }
    .code_red{
        color: #c04d58;
    }
    .code_yellow{
        color: #E4802E;
    }
}

.code_copied{
    display: flex;
    position: absolute;
    padding: 4px 16px;
    border-radius: 6px;
    background: #111111;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    pointer-events: none;
    user-select: none;

    &::before{
        content: "";
        position: absolute;
        background: #111111;
        width: 16px;
        height: 16px;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        z-index: -1;
        border-radius: 0 0 4px 0;
    }

    &.copied{
        visibility: visible;
        animation: copiedAnimate 2.8s ease forwards;
    }
}

@keyframes copiedAnimate {
    0%{
        opacity: 0;
        translate: 0 12px;
    }
    15% {
        opacity: 1;
        translate: 0 0;
    }
    85%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}