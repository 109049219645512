.navbar-modal{
    width: 100%;
    height: 576px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    background: #101010;
    z-index: 991;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 12px;
    will-change: transform, animation, opacity;

    .container{
        padding-bottom: 48px;
        display: flex;

        .navbar-modals-box{
            display: flex;
            gap: 32px;

            &:hover{
                .circle{
                    opacity: 1;
                }
            }
        }
    }
}