.navbar-modal-card{
    position: relative;
    display: flex;
    width: 300px;
    aspect-ratio: 3/4;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: none;
    transition: 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 12px;

    @keyframes cardHover {
        from{
            scale: 1;
        }   
        10%{
            scale: 1.03;
        }
        to{
            scale: 1;
        }
    }

    &:hover{
        .circle, .inner-circle{
            opacity: 1;
        }  

        animation: cardHover 0.3s ease;
    }

    .circle, .inner-circle{
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 600px;
        height: 600px;
        background: radial-gradient(600px at center, rgba(255, 255, 255, 0.1), transparent 40%);
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .inner-circle{
        z-index: 2;
    }

    .circle{
        width: 800px;
        height: 800px;
        background: radial-gradient(400px at center, rgba(255, 255, 255, 0.5), transparent 60%);
    }

    .navbar-modal-inner-card{
        position: relative;
        width: 298px;
        height: 398px;
        background: rgb(23, 23, 23);
        border-radius: 12px;
        font-weight: 500;
        overflow: hidden;
        display: flex;

        .navbar-card-image{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 60%;
            -webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1.0), transparent);
            display: flex;
            overflow: hidden;
            pointer-events: none;
            z-index: 1;
            transition: 0.3s ease;

            img{
                position: absolute;
                display: flex;
                object-fit: cover;
                width: 100%;
                top: 0;
                -webkit-mask-image: linear-gradient(to bottom, black, transparent);
                mask-image: linear-gradient(to bottom, black, transparent);
            }

            &.apex{
                filter: grayscale(1) blur(8px);
            }
        }

        .navbar-card-content{
            display: flex;
            width: 100%;
            padding: 0 24px 24px 24px;
            align-self: flex-end;
            flex-direction: column;

            .navbar-card-title{
                display: flex;
                align-items: center;
                gap: 16px;
                font-size: 20px;
                color: #f7f8f8;
                font-weight: 700;

                svg{
                    border-radius: 4px;
                }
            }

            .navbar-card-subtitle{
                font-size: 14px;
                color: #8a8f98;
                margin-left: 36px;
                margin: 12px 0 16px 36px;
            }

            a{
                cursor: pointer;
                padding: 10px 0;width: 100%;
                background: #222226;
                border-radius: 8px;
                text-align: center;
                letter-spacing: 0px;
                transition: 0.3s ease;
                color: #f7f8f8;

                &:hover{
                    background: #29292e;
                }
            }
        }
    }

    .apex-title{
        font-size: 20px;
        color: #f7f8f8;
        font-weight: 700;
        width: 100%;
        align-self: center;
        text-align: center;
    }
}