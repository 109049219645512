.rust_loader{
    padding: 64px 0;

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;

        .box-gradient{
            opacity: 0;
            
            &:before{
                --color-a:#70b856;
                --color-b:#8deded;
                --color-c:#745df7;
            }
        }

        &.active{
            .box-gradient{
                animation: fadeIn 0.5s 1s forwards;
            }

            .rust_loader_title{
                animation: fadeIn 0.4s 1s forwards;
            }

            .rust_loader_inctructions_header{
                animation: fadeIn 0.4s 1.25s forwards;
            }

            .rust_loader_instructions_list{
                animation: fadeIn 0.4s 1.5s forwards;
            }

            .rust_loader_download{
                animation: fadeIn 0.4s 1.75s forwards;
            }
        }
    }
}

.rust_loader_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
    opacity: 0;
}

.rust_loader_main{
    font-weight: 500;
    margin-bottom: 8px;
}

.rust_loader_sub{
    display: flex;
    color: rgba(255, 255, 255, 0.75);
    line-height: 1.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    a{
        color: rgba(255, 255, 255, 0.75);
        text-decoration: underline;
    }
}

.rust_loader_instructions_section{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 640px;
}

.rust_loader_inctructions_header{
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    p{
        img{
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-top: 4px;
            margin-right: 8px;
        }

        display: flex;
        color: #8deded;

        // &.red{
        //     color: #edc08d;
        // }
    }
}

.rust_loader_inctructions_title{
    display: flex;
    align-items: center;
    gap: 12px;

    div{
        width: 30px;
        height: 30px;

        img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    span{
        font-size: 20px;
        font-weight: 600;
    }
}

.rust_loader_instructions_list{
    opacity: 0;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rust_loader_instructions_item{
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);

    transition: 0.3s ease;
    &:hover{
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15),
                    0 2px 16px rgba(0,0,0,0.2);
        background: rgba(0, 0, 0, 0.4);
    }
}

.rust_loader_instructions_item_left{
    display: flex;
    align-items: center;
    gap: 16px;
}

.rust_loader_instructions_item_icon{
    display: block;
    max-width: 24px;
    margin: 4px;
    max-height: 24px;

    img{
        width: 100%;
        height: 100%;
    }
}

.rust_loader_instructions_item_title{
    p{
        font-weight: 500;
        color: #ffffff;                
    }

    span{
        font-size: 14px;
        color: rgba(255, 255, 255, 0.5);            
    }
}

.rust_loader_instructions_item_right{
    height: 100%;
    display: flex;
    align-items: center;
}

.rust_loader_instructions_item_button{
    border-radius: 8px;
    background: rgba(22, 184, 228, 0.5);
    color: #fff;
    padding: 8px 20px;

    transition: 0.3s ease;
    &:hover{
        box-shadow: inset 0 0 0 1px rgba(22, 184, 228, 0.3);
        background: rgba(22, 184, 228, 0.15);
    }
}

.rust_loader_download{
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    gap: 12px;
}

.download_button{
    display: flex;
    padding: 16px 24px;
    border-radius: 16px;
    background: rgba(22, 184, 228, 0.5);
    box-shadow: inset 0 0 0 2px rgba(22, 184, 228, 0.3),
                0 4px 8px rgba(0, 0, 0, 0.5);
    gap: 16px;

    span{
        font-weight: 500;
    }

    cursor: pointer;
    transition: 0.3s ease;

    &:hover{
        box-shadow: inset 0 0 0 2px rgba(22, 184, 228, 0.5),
                    0 4px 16px rgba(0, 0, 0, 0.3);
        background: rgba(22, 184, 228, 0.4);
    }

    &:active{
        scale: 1.05 1;
    }
}

.download_button_icon{
    display: block;
    max-width: 24px;
    max-height: 24px;

    img{
        width: 100%;
        height: 100%;
    }
}

.rust_loader_download_info{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    color: rgba(255,255,255, 0.6);
}

.rust_loader_download_password{
    img{
        display: inline-block;
        max-width: 16px;
        max-height: 16px;
        margin-right: 8px;
        margin-top: 4px;
    }

    display: flex;
    color: #8deded;

    code{
        // align-self: flex-end;
        // margin-top: 4px;
        color: #f17777;
        font-size: 16px;
        font-weight: 500;
    }
}

@media screen and (max-width: 550px) {
    .rust_loader{
        padding: 48px 0;
    }

    .rust_loader_inctructions_title{
        font-size: 18px;

        div{
            width: 28px;
            height: 28px;
        }
    }

    .rust_loader_inctructions_header{
        p{
            font-size: 14px;
        }
    }

    .rust_loader_instructions_item_icon{
        display: none;
    }

    .rust_loader_instructions_item{
        flex-direction: column;
        gap: 8px;
    }

    .rust_loader_instructions_item_right{
        width: 100%;
        justify-content: center;
    }

    .rust_loader_instructions_item_button{
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 440px) {
    .rust_loader{
        padding: 32px 0;
    }
}